import axios from 'axios';
import { routes } from './routes';

const formDataConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

export const campaigns = {
  admin_index: (
    { page, per_page, search, start_date, end_date, affiliation, activity_type },
    callback = () => {},
  ) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const { token } = source;
    const axiosHeaders = { cancelToken: token };

    callback(source);

    return axios.get(`${routes.campaigns}/admin_index`, {
      params: {
        search,
        start_date: start_date?.format('YYYY-MM-DD'),
        end_date: end_date?.format('YYYY-MM-DD'),
        page,
        per_page,
        affiliation,
        activity_type,
      },
      ...axiosHeaders,
    });
  },
  twilio_registration_index: ({ page, per_page, search }) => {
    return axios.get(`${routes.campaigns}/twilio_registration_index`, {
      params: {
        page,
        per_page,
        search,
      },
    });
  },
  create: values => {
    return axios.post(routes.campaigns, { campaign: values });
  },
  create_new_signup: values => {
    return axios.post(`${routes.campaigns}/new_signup`, values);
  },
  admin_show: id => {
    return axios.get(`${routes.campaigns}/${id}/admin_show`);
  },
  show: id => {
    return axios.get(`${routes.campaigns}/${id}`);
  },
  actions: id => {
    return axios.get(`${routes.campaigns}/${id}/actions`);
  },
  notifications: id => {
    return axios.get(`${routes.campaigns}/${id}/notifications`);
  },
  legacyUserCampaigns: ({ admin, campaign_id, page, per_page, search, sort, desc }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/legacy_user_campaigns`, {
      params: {
        admin,
        desc,
        page,
        per_page,
        search,
        sort,
      },
    });
  },
  getUserCampaigns: ({ campaign_id, page, per_page, search, sort_field, order, filter }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/user_campaigns`, {
      params: {
        filter,
        order,
        page,
        per_page,
        search,
        sort_field,
      },
    });
  },
  invite_user: ({ campaign_id, params }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/invite_admin`, params);
  },
  change_role: ({
    campaign_id,
    user_ids,
    role,
    sync_permission,
    broadcast_permission,
    welcome_permission,
    select_all,
    billing_permission,
    manage_users_permission,
    export_permission,
    settings_permission,
    broadcast_activity_start_permission,
  }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/change_roles`, {
      user_ids: user_ids,
      role: role,
      sync_permission: sync_permission,
      broadcast_permission: broadcast_permission,
      welcome_permission: welcome_permission,
      select_all: select_all,
      billing_permission: billing_permission,
      manage_users_permission: manage_users_permission,
      export_permission: export_permission,
      settings_permission: settings_permission,
      broadcast_activity_start_permission: broadcast_activity_start_permission,
    });
  },

  create_subscription: ({ campaign_id, plan }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/create_subscription`, { plan });
  },
  get_parent_campaigns: ({ page, per_page, search }) => {
    return axios.get(`${routes.campaigns}/parent_campaigns`, {
      params: {
        search: search,
        page: page,
        per_page: per_page,
      },
    });
  },

  contact_lists: ({
    campaign_id,
    desc,
    page,
    per_page,
    search,
    folder_ids,
    is_broadcast,
    is_opt_in_list,
    withOptinData,
  }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/contact_lists`, {
      params: {
        desc, // Whether to sort the results in descending order (base field: created_at)
        folder_ids,
        is_broadcast,
        is_opt_in_form_data_rendered: withOptinData,
        is_opt_in_list,
        page,
        per_page,
        search,
      },
    });
  },

  contact_list_resync: ({ id }) => axios.post(`${routes.contact_lists}/${id}/resync`),

  user_lists: ({ campaign_id, page, per_page, search, folder_ids, is_broadcast }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/user_lists`, {
      params: {
        search: search,
        page: page,
        per_page: per_page,
        folder_ids,
        is_broadcast,
      },
    });
  },

  change_user_list: ({ list_id, user_ids, from_list_id, filter }) => {
    const body = user_ids
      ? {
          user_ids: user_ids,
        }
      : {
          from_list_id: from_list_id,
          filter: filter,
        };

    return axios.post(`${routes.user_lists}/${list_id}/add_users`, body);
  },
  create_user_list: ({
    campaign_id,
    name,
    user_ids,
    is_broadcast,
    folder_ids,
    compound_filter,
  }) => {
    return axios.post(`${routes.user_lists}`, {
      campaign_id,
      compound_filter,
      folder_ids,
      is_broadcast,
      name,
      user_ids,
    });
  },
  copy_user_list: ({ filter, name, user_list_id }) => {
    return axios.post(`${routes.user_lists}/${user_list_id}/copy`, {
      filter,
      name,
    });
  },
  remove_from_user_list: ({ user_list_id, user_ids }) => {
    return axios.post(`${routes.user_lists}/${user_list_id}/remove_users`, {
      user_ids,
    });
  },

  addTagsToUser: ({ campaignId, tagIds, userId }) =>
    axios.post(`${routes.campaigns}/${campaignId}/set_all_taggings_for_user`, {
      tag_ids: tagIds,
      user_id: userId,
    }),

  applyTagsToUsers: ({ campaignId, tagIds, userIds }) =>
    axios.post(`${routes.campaigns}/${campaignId}/apply_tags_to_users`, {
      tag_ids: tagIds,
      user_ids: userIds,
    }),

  // getConversations
  getMassages: ({
    campaign_id,
    activity_id,
    page,
    per_page,
    needs_attention,
    contact_id,
    assigned_user_id,
  }) => {
    const path = routes.activities.replace(':campaign_id', campaign_id);

    return axios.get(`${path}/${activity_id}/message_threads`, {
      params: {
        assigned_user_id,
        contact_id,
        needs_attention,
        page,
        per_page,
      },
    });
  },

  // getConversations Message Thread
  getMessageThread: async (
    { id: message_thread_id, per_page = 30, page = 1, ...rest } = {},
    axiosHeaders,
  ) => {
    // this endpoint can take `exclude_initial_messages` as a query param to exclude the initial messages
    return axios.get(`/api/v2/message_threads/${message_thread_id}`, {
      params: { page, per_page, ...rest },
      ...axiosHeaders,
    });
  },

  get_available_area_codes: ({ campaign_id }) =>
    axios.get(`${routes.campaigns}/${campaign_id}/available_area_codes`),

  getUserCampaignTags: ({ userCampaignId }) =>
    axios.get(`${routes.user_campaigns}/${userCampaignId}/tags`),

  get_contact: ({ campaign_id, contact_id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/contacts/${contact_id}`);
  },
  delete_list: ({ contact_list_id }) => {
    return axios.post(`${routes.contact_lists}/${contact_list_id}/delete`);
  },
  delete_user_list: ({ user_list_id }) => {
    return axios.post(`${routes.user_lists}/${user_list_id}/delete`);
  },
  clear_assignments: ({ contact_list_id }) => {
    return axios.post(`${routes.contact_lists}/${contact_list_id}/clear`);
  },
  contact_list: ({ contact_list_id, page, per_page, search, filter }) => {
    return axios.get(`${routes.contact_lists}/${contact_list_id}`, {
      params: {
        filter: filter,
        page: page,
        per_page: per_page,
        search: search,
      },
    });
  },

  contact_list_search: ({ campaignId, page, per_page, search, filter }) => {
    return axios.get(`/api/v2/campaigns/${campaignId}/contacts`, {
      params: {
        filter: filter,
        page: page,
        per_page: per_page,
        search: search,
      },
    });
  },

  contact_list_metrics: ({ contact_list_id }) => {
    return axios.get(`${routes.contact_lists}/${contact_list_id}/metrics`);
  },
  user_list_legacy: ({ user_list_id, page, per_page, search, filter }) => {
    return axios.get(`${routes.user_lists}/${user_list_id}`, {
      params: {
        filter: filter,
        search: search,
        page: page,
        per_page: per_page,
      },
    });
  },
  userList: ({ user_list_id, page, perPage, search, filter, sortField, order }) => {
    return axios.get(`${routes.user_lists}/${user_list_id}/user_campaigns`, {
      params: {
        filter,
        order,
        page,
        per_page: perPage,
        search,
        sort_field: sortField,
      },
    });
  },

  user_list_metrics: ({ user_list_id }) => {
    return axios.get(`${routes.user_lists}/${user_list_id}/metrics`);
  },

  create_contact_list: ({
    campaign_id,
    contact_list_id,
    filter,
    new_list_name,
    is_broadcast,
    contact_ids,
  }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/create_contact_list`, {
      contact_ids,
      contact_list_id: contact_list_id,
      filter: filter,
      is_broadcast: is_broadcast,
      new_list_name: new_list_name,
    });
  },
  update_contact_list: ({ contact_list_id, params }) => {
    return axios.patch(`${routes.contact_lists}/${contact_list_id}`, {
      contact_list: params,
    });
  },
  import_list: ({ campaign_id, contact_list }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/import_contact_list`, contact_list);
  },
  import_user_list: ({ campaign_id, user_list }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/import_user_list`, user_list);
  },
  update_user_list: ({ user_list_id, params }) => {
    return axios.patch(`${routes.user_lists}/${user_list_id}`, {
      user_list: params,
    });
  },
  assign_contacts: ({ campaign_id, contact_list_id, user_list_id, filter, search }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/assign_contacts`, {
      contact_list_id: contact_list_id,
      user_list_id: user_list_id,
      filter: filter,
      search,
    });
  },
  reassign_contacts: ({ campaign_id, activity_id, user_id }) => {
    return axios.post(
      `${routes.campaigns}/${campaign_id}/user_activities/${activity_id}/assign_contacts`,
      {
        user_id: user_id,
      },
    );
  },

  /************************* New Reassignment ************************/
  // There are several ways to reassign contact, so on the backend side we have 28 different endpoints and most of them differ only by type, but have the same structure
  // The activity type must be one of activities (meaning all the types), broadcast_activities, text_a_list_activities, canvassing_activities
  // I prefer this way instead of creating 28 different functions that do basically the same thing.

  reassignActivitiesContacts: ({ activityId, ids, userId, activityType }) =>
    axios.post(`/${routes[activityType]}/${activityId}/reassign_contacts`, {
      contact_ids: ids,
      user_id: userId,
    }),

  reassignActivitiesContactsToUserList: ({ activityId, ids, activityType, listId }) =>
    axios.post(`/${routes[activityType]}/${activityId}/reassign_contacts_to_user_list/${listId}`, {
      contact_ids: ids,
    }),

  reassignActivitiesUsers: ({ activityId, ids, userId, activityType }) =>
    axios.post(`${routes[activityType]}/${activityId}/reassign_users`, {
      from_user_ids: ids,
      to_user_id: userId,
    }),

  reassignActivitiesUsersToUserList: ({ activityId, ids, listId, activityType }) =>
    axios.post(`${routes[activityType]}/${activityId}/reassign_users_to_user_list/${listId}`, {
      from_user_ids: ids,
    }),

  reassignActivitiesUserToUser: ({ campaignId, currentUserId, ids, userId }) =>
    axios.post(
      `${routes.campaigns}/${campaignId}/activities/reassign_activities_user_to_user/${userId}`,
      {
        activity_ids: ids,
        from_user_id: currentUserId,
      },
    ),

  reassignActivitiesUserToUserList: ({ campaignId, currentUserId, ids, userId, listId }) =>
    axios.post(
      `${routes.campaigns}/${campaignId}/activities/reassign_activities_user_to_user_list/${listId}`,
      {
        activity_ids: ids,
        from_user_id: currentUserId,
      },
    ),

  reassignContactsWithoutActivity: ({
    campaignId,
    activityType,
    ids,
    userId,
    filter,
    selectAll,
    search,
    contactListId,
  }) =>
    axios.post(
      `${routes.campaigns}/${campaignId}/${activityType}/reassign_contacts_without_activity`,
      {
        contact_ids: ids,
        contact_list_id: contactListId,
        filter,
        search,
        select_all: selectAll,
        user_id: userId,
      },
    ),
  reassignContactsWithoutActivityToUserList: ({
    campaignId,
    activityType,
    ids,
    listId,

    filter,
    selectAll,
    search,
    contactListId,
  }) =>
    axios.post(
      `${routes.campaigns}/${campaignId}/${activityType}/reassign_contacts_without_activity_to_user_list/${listId}`,
      {
        contact_ids: ids,
        contact_list_id: contactListId,
        filter,
        search,
        select_all: selectAll,
      },
    ),

  reassignUsersWithoutActivity: ({ campaignId, activityType, ids, userId }) =>
    axios.post(
      `${routes.campaigns}/${campaignId}/${activityType}/reassign_users_without_activity`,
      {
        from_user_ids: ids,
        to_user_id: userId,
      },
    ),

  reassignUsersWithoutActivityToUserList: ({ campaignId, activityType, ids, userId, listId }) =>
    axios.post(
      `${routes.campaigns}/${campaignId}/${activityType}/reassign_users_without_activity_to_user_list/${listId}`,
      {
        from_user_ids: ids,
      },
    ),

  /*****************************************************************/

  reassign_multiple_contacts: ({ campaign_id, user_id, activities_ids }) => {
    return axios.post(
      `${routes.campaigns}/${campaign_id}/user_activities/assign_multiple_contacts`,
      {
        ids: activities_ids,
        user_id: user_id,
      },
    );
  },
  add_to_contact_list: ({ selectedAll, list_id, contact_ids, from_list_id, filter }) => {
    let body;

    if (selectedAll) {
      body = {
        filter,
        from_list_id,
      };
    } else if (filter) {
      body = {
        filter,
        from_list_id,
      };
    } else {
      body = {
        contact_ids,
      };
    }

    return axios.post(`${routes.contact_lists}/${list_id}/add_contacts`, body);
  },

  remove_contacts_in_background: ({ list_id, contact_ids, select_all, filter }) =>
    axios.post(`${routes.contact_lists}/${list_id}/remove_contacts_in_background`, {
      contact_ids,
      filter,
      select_all,
    }),

  remove_from_contact_list: ({ list_id, contact_ids }) => {
    return axios.post(`${routes.contact_lists}/${list_id}/remove_contacts`, {
      contact_ids,
    });
  },

  reports: ({ campaign_id, page, per_page, search }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/user_reports`, {
      params: {
        search: search,
        page: page,
        per_page: per_page,
      },
    });
  },
  adminActivities: ({
    aasm_state,
    campaign_id,
    page,
    per_page,
    type,
    folder_ids,
    sort_by,
    additional_filter,
    search,
  }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activities/admin_activities`, {
      params: {
        aasm_state,
        additional_filter,
        folder_ids,
        page,
        per_page,
        search,
        sort_by,
        type,
      },
    });
  },
  adminActivitiesSlim: ({ campaign_id, page, per_page, search, type }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activities/admin_activities_slim`, {
      params: {
        page,
        per_page,
        search,
        type,
      },
    });
  },
  activities: ({ campaign_id, page, per_page, type, sort_by }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activities`, {
      params: {
        type: type,
        page: page,
        per_page: per_page,
        sort_by: sort_by,
      },
    });
  },

  upcoming_invoices_total_amount: () =>
    axios.get(`${routes.campaigns}/upcoming_invoices_total_amount`),

  user_activities: ({ campaign_id, activity_id, per_page, page, filter, sort, desc }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/activities/${activity_id}/user_activities`,
      {
        params: {
          page: page,
          per_page: per_page,
          sort: sort,
          desc: desc,
          filter: filter,
        },
      },
    );
  },
  send_action: ({ campaign_id, activity_id, user_ids, select_all, send_to_teams }) => {
    return axios.post(
      `${routes.campaigns}/${campaign_id}/activities/${activity_id}/send_user_activities`,
      {
        user_ids: user_ids,
        select_all: select_all,
        send_to_teams,
      },
    );
  },
  send_to_list: ({ campaign_id, activity_id, user_list_id, filter, send_to_teams }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/activities/${activity_id}/send_to_list`, {
      user_list_id: user_list_id,
      filter: filter,
      send_to_teams,
    });
  },
  send_message: ({ campaign_id, message, user_ids, select_all, send_to_team, user_list_id }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/send_direct_messages`, {
      user_ids: user_ids,
      user_list_id: user_list_id,
      message: message,
      select_all: select_all,
      send_to_team: send_to_team,
    });
  },
  send_reminder: params => {
    let { campaign_id } = params;
    delete params.campaign_id;
    return axios.post(`${routes.campaigns}/${campaign_id}/send_reminders`, params);
  },
  remove_team_members: ({ campaign_id, remove_teams, user_ids }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/remove_team_members`, {
      user_ids: user_ids,
      remove_teams,
    });
  },
  hooks: ({ campaign_id, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/hooks`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  create_hook: ({ campaign_id, params }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/hooks`, { hook: params });
  },
  update_hook: ({ campaign_id, hook_id, params }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}/hooks/${hook_id}`, { hook: params });
  },

  // Campaign folders CRUD endpoints
  create_campaign_folder: ({ campaign_id, params }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/folders`, { folder: params });
  },

  campaign_folders: ({ campaign_id, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/folders`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },

  update_campaign_folder: ({ campaign_id, folder_id, folder_name }) => {
    if (!folder_id || !folder_name)
      throw new Error('folder not provided to the update folder endpoint');

    return axios.patch(`${routes.campaigns}/${campaign_id}/folders/${folder_id}`, {
      folder: {
        name: folder_name,
      },
    });
  },

  delete_campaign_folder: ({ campaign_id, folder_id }) => {
    if (!folder_id) throw new Error('folder id not provided to the delete folder endpoint');

    return axios.delete(`${routes.campaigns}/${campaign_id}/folders/${folder_id}`);
  },

  tags: ({ campaign_id, folder_ids, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/tags`, {
      params: {
        page: page,
        per_page: per_page,
        folder_ids,
      },
    });
  },
  create_tag: ({ campaign_id, params }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/tags`, { tag: params });
  },
  update_tag: ({ campaign_id, tag_id, params }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}/tags/${tag_id}`, { tag: params });
  },
  custom_fields: ({ campaign_id, folder_ids, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/custom_fields`, {
      params: {
        folder_ids,
        page: page,
        per_page: per_page,
      },
    });
  },
  create_custom_field: ({ campaign_id, params }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/custom_fields`, { custom_field: params });
  },
  update_custom_field: ({ campaign_id, custom_field_id, params }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}/custom_fields/${custom_field_id}`, {
      custom_field: params,
    });
  },
  create_sync_instruction: ({ campaign_id, params }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/sync_instructions`, {
      sync_instruction: params,
    });
  },
  sync_instructions: ({ campaign_id, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/sync_instructions`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  update_sync_instruction: ({ campaign_id, instruction_id, params }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}/sync_instructions/${instruction_id}`, {
      sync_instruction: params,
    });
  },
  integration_credentials: ({ campaign_id, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/integration_credentials`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  activity_scripts: ({ campaign_id, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activity_scripts`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  activity_script_search: ({
    campaign_id,
    search,
    page,
    perPage,
    activity_type,
    activity_ids,
    activity_script_ids,
    script_type,
  }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activity_scripts/search`, {
      params: {
        activity_ids, // can either be an array or a number
        activity_script_ids,
        activity_type,
        page: page || 1,
        per_page: perPage || 20,
        q: search,
        script_type,
      },
    });
  },
  request_export: ({ campaign_id, activity_id, contact_list_id, page, per, table_name }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/request_export`, {
      table_name,
      activity_id,
      contact_list_id,
    });
  },
  update_campaign: ({ campaign_id, campaign }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}`, { campaign: campaign });
  },
  delete_campaign: ({ campaign_id }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/delete`);
  },
  deactivate_campaign: ({ campaign_id }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}/deactivate`);
  },
  activate_campaign: ({ campaign_id }) => {
    return axios.patch(`${routes.campaigns}/${campaign_id}/activate`);
  },
  update_public_campaign_info: ({ campaign_id, campaign }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/update_public_info`, {
      campaign: campaign,
    });
  },
  requestAdminAccess: ({ campaign_id, duration, reason }) =>
    axios.post(`${routes.campaigns}/${campaign_id}/temp_admin_access`, { duration, reason }),

  // Old routes
  coordinated_campaigns: ({ id, page, per }) => {
    return axios.get(`${routes.campaigns}/${id}/coordinated_campaigns`, {
      params: {
        page: page,
        per: per,
      },
    });
  },
  friends: ({ id, page, per }) => {
    return axios.get(`${routes.campaigns}/${id}/friends`, {
      params: {
        page: page,
        per: per,
      },
    });
  },
  contacts: ({ id, page, per }) => {
    return axios.get(`${routes.campaigns}/${id}/contacts`, {
      params: {
        page: page,
        per: per,
      },
    });
  },
  feedback: ({ id, page, per }) => {
    return axios.get(`${routes.campaigns}/${id}/feedback`, {
      params: {
        page: page,
        per: per,
      },
    });
  },
  do_not_call: ({ id, page, per }) => {
    return axios.get(`${routes.campaigns}/${id}/do_not_call`, {
      params: {
        page: page,
        per: per,
      },
    });
  },
  messages_chart_data: ({ id, start, end }) => {
    return axios.get(`${routes.campaigns}/${id}/messages_chart_data`, {
      params: {
        start: start,
        end: end,
      },
    });
  },
  supporters_chart_data: ({ id, start, end }) => {
    return axios.get(`${routes.campaigns}/${id}/supporters_chart_data`, {
      params: {
        start: start,
        end: end,
      },
    });
  },
  total_voter_friends: ({ id }) => {
    return axios.get(`${routes.campaigns}/${id}/total_voter_friends`, {
      params: {},
    });
  },
  request_activation: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/request_activation`);
  },

  request_reactivation: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/request_reactivation`);
  },

  predeploy: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/predeploy`);
  },

  activate: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activate`);
  },

  reactivate: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/reactivate`);
  },

  deactivate: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/deactivate`);
  },

  reject: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/reject`);
  },

  users: campaign_id => {
    return axios.get(`${routes.campaigns}/${campaign_id}/users`);
  },

  export_job_sync_types: ({
    page,
    per_page = 500,
    campaign_id,
    integration_credential_id,
    database_mode,
  }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/export_job_sync_types`,
      {
        params: {
          page: page,
          per_page: per_page,
          database_mode: database_mode,
        },
      },
    );
  },

  folders: ({ page, per_page = 500, campaign_id, integration_credential_id, database_mode }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/folders`,
      {
        params: {
          page: page,
          per_page: per_page,
          database_mode: database_mode,
        },
      },
    );
  },

  saved_lists: ({
    page,
    per_page = 500,
    campaign_id,
    integration_credential_id,
    database_mode,
    folder_id,
  }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/saved_lists`,
      {
        params: {
          page: page,
          per_page: per_page,
          database_mode: database_mode,
          folder_id: folder_id,
        },
      },
    );
  },

  custom_field_sync_types: ({ page, per_page = 500, campaign_id, integration_credential_id }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/custom_field_sync_types`,
      {
        params: {
          page: page,
          per_page: per_page,
        },
      },
    );
  },

  custom_field_sync_type_options: ({
    page,
    per_page = 500,
    campaign_id,
    integration_credential_id,
    sync_type,
    database_mode,
  }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/custom_field_sync_type_options`,
      {
        params: {
          sync_type: sync_type,
          page: page,
          per_page: per_page,
          database_mode: database_mode,
        },
      },
    );
  },

  sync_types: ({ page, per_page = 500, campaign_id, integration_credential_id }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/sync_types`,
      {
        params: {
          page: page,
          per_page: per_page,
        },
      },
    );
  },

  sync_type_options: ({
    page,
    per_page = 500,
    campaign_id,
    integration_credential_id,
    sync_type,
    database_mode,
  }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/sync_type_options`,
      {
        params: {
          sync_type: sync_type,
          page: page,
          per_page: per_page,
          database_mode: database_mode,
        },
      },
    );
  },

  pdi_flags: ({ page, per_page = 500, campaign_id, integration_credential_id }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/pdi_flags`,
      {
        params: {
          page: page,
          per_page: per_page,
        },
      },
    );
  },

  pdi_questions: ({ page, per_page = 5, campaign_id, integration_credential_id }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/integration_credentials/${integration_credential_id}/pdi_questions`,
      {
        params: {
          page: page,
          per_page: per_page,
        },
      },
    );
  },

  update: (values, id) => {
    const config = formDataConfig;
    return axios.patch(`${routes.campaigns}/${id}`, values, config);
  },

  update_membership: (campaign_id, membership_id, coupon_id) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/update_membership`, {
      membership_id,
      coupon_id,
    });
  },

  update_payment_method: (campaign_id, stripe_id) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/update_payment_method`, { stripe_id });
  },

  promote_campaign_user: (campaign_id, campaign_user_id) => {
    return axios.patch(
      `${routes.campaigns}/${campaign_id}/campaigns_users/${campaign_user_id}/promote`,
    );
  },

  get_campaign_users: ({ id, page, per }) => {
    return axios.get(`${routes.campaigns}/${id}/campaigns_users`, {
      params: {
        page: page,
        per: per,
      },
    });
  },

  demote_campaign_user: (campaign_id, campaign_user_id) => {
    return axios.patch(
      `${routes.campaigns}/${campaign_id}/campaigns_users/${campaign_user_id}/demote`,
    );
  },

  saveAction: values => {
    const config = formDataConfig;
    const method = values.hasOwnProperty('id') ? 'patch' : 'post';
    const action_id = values.hasOwnProperty('id') ? values.id : '';
    const fd = new FormData();

    Object.keys(values).forEach(property => {
      if (property != 'initial_message_templates' && property != 'response_message_templates') {
        fd.append(`campaign_action[${property}]`, values[property]);
      }
    });

    values.initial_message_templates.forEach((message, index) => {
      Object.keys(message).forEach(property => {
        fd.append(
          `campaign_action[initial_message_templates_attributes][${index}][${property}]`,
          message[property],
        );
      });
    });

    values.response_message_templates.forEach((message, index) => {
      Object.keys(message).forEach(property => {
        fd.append(
          `campaign_action[response_message_templates_attributes][${index}][${property}]`,
          message[property],
        );
      });
    });

    return axios[method](
      `${routes.campaigns}/${values.campaign_id}/actions/${action_id}`,
      fd,
      config,
    );
  },

  save_notification: (values, campaign_id) => {
    const method = values.hasOwnProperty('id') ? 'patch' : 'post';
    const notify_id = values.hasOwnProperty('id') ? values.id : '';
    return axios[method](`${routes.campaigns}/${campaign_id}/notifications/${notify_id}`, {
      notification: values,
    });
  },

  request_send: (campaign_id, notify_id) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/notifications/${notify_id}/request_test`);
  },

  destroy_notification: (notification_id, campaign_id) => {
    return axios.delete(`${routes.campaigns}/${campaign_id}/notifications/${notification_id}`);
  },

  requested_campaigns: () => {
    return axios.get(`${routes.campaigns}/requested_campaigns`);
  },

  all_campaigns: (page, filter, search) => {
    return axios.get(`${routes.campaigns}`, { params: { page, filter, search } });
  },
  export_metrics: params => {
    return axios.post(`${routes.campaigns}/export_metrics`, params);
  },

  setOnboardingActivity: (campaign_id, activity_id) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/set_onboarding_activity`, {
      activity_id,
    });
  },
  unsetOnboardingActivity: campaign_id => {
    return axios.post(`${routes.campaigns}/${campaign_id}/unset_onboarding_activity`);
  },

  getSuppliedVotingForms: ({ campaign_id, page = 1, per_page = 25 }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/supplied_voting_forms`, {
      params: { page, per_page },
    });
  },

  data_sync_success_summary: ({ campaign_id, id }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/activities/${id}/data_sync_success_summary`,
    );
  },

  customization_data_syncs: ({ campaign_id, id }) => {
    return axios.get(
      `${routes.campaigns}/${campaign_id}/activities/${id}/customization_data_syncs`,
    );
  },

  tagging_data_syncs: ({ campaign_id, id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/activities/${id}/tagging_data_syncs`);
  },

  user_activity_data_syncs: ({ campaign_id, id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/user_activities/${id}/data_syncs`);
  },

  mark_contact_hostile: ({ contact_id }) => {
    return axios.post(`/api/v2/contacts/${contact_id}/mark_hostile`);
  },

  mark_contact_friendly: ({ contact_id }) => {
    return axios.post(`/api/v2/contacts/${contact_id}/mark_friendly`);
  },

  addLocalizationOnboarding: ({ campaign_id, values }) => {
    delete values['campaign_localization']['_type'];
    delete values['campaign_localization']['id'];
    return axios.post(`${routes.campaigns}/${campaign_id}/localizations`, values);
  },

  updateLocalizationOnboarding: ({ campaign_id, language_string, values }) => {
    delete values['campaign_localization']['_type'];
    delete values['campaign_localization']['id'];
    return axios.patch(
      `${routes.campaigns}/${campaign_id}/localizations/${language_string}`,
      values,
    );
  },

  getLocalizationData: ({ campaign_id, language_string }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/localizations/${language_string}`);
  },

  deleteLocalization: ({ campaign_id, language_string }) => {
    return axios.delete(`${routes.campaigns}/${campaign_id}/localizations/${language_string}`);
  },

  optInForms: ({ campaign_id, page, per_page }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/opt_in_forms`, {
      params: {
        page,
        per_page,
      },
    });
  },

  organization: ({ campaign_id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/organization`);
  },

  create_dependent_tags: ({ campaign_id, id, campaign_ids }) => {
    return axios.post(`${routes.campaigns}/${campaign_id}/tags/${id}/create_dependent_tags`, {
      tag: {
        campaign_ids,
      },
    });
  },

  get_tag_info: ({ campaign_id, id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/tags/${id}`);
  },
  create_dependent_custom_fields: ({ campaign_id, id, campaign_ids }) => {
    return axios.post(
      `${routes.campaigns}/${campaign_id}/custom_fields/${id}/create_dependent_custom_fields`,
      {
        custom_field: {
          campaign_ids,
        },
      },
    );
  },

  get_custom_field_info: ({ campaign_id, id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/custom_fields/${id}`);
  },

  get_sync_instructions: ({ campaign_id, id }) => {
    return axios.get(`${routes.campaigns}/${campaign_id}/sync_instructions/${id}`);
  },
  create_dependent_sync_instructions: ({ campaign_id, id, campaign_ids }) => {
    return axios.post(
      `${routes.campaigns}/${campaign_id}/sync_instructions/${id}/create_dependent_sync_instructions`,
      {
        sync_instruction: {
          campaign_ids,
        },
      },
    );
  },
};

export const categories = {
  index: () => {
    return axios.get(routes.categories);
  },
  show: id => {
    return axios.get(`${routes.categories}/${id}`);
  },
  create: values => {
    return axios.post(routes.categories, { category: values });
  },
  update: ({ category_id, category }) => {
    return axios.patch(`${routes.categories}/${category_id}`, { category });
  },
};

export const causes = {
  index: () => {
    return axios.get(routes.causes);
  },
  show: id => {
    return axios.get(`${routes.causes}/${id}`);
  },
  create: values => {
    return axios.post(routes.causes, { cause: values });
  },
  update: ({ cause_id, cause }) => {
    return axios.patch(`${routes.causes}/${cause_id}`, { cause });
  },
};
